import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import { connect, useDispatch } from "react-redux";
import { getFairyTale } from "./Actions/fairytale";
import ContentBlogLoader from "./Components/Loader/contentLoader";
import BodyBlogLoader from "./Components/Loader/bodyLoader";
import images_nodata from "../src/images/new-menu-bg/images_nodata.png"
import axios from "axios";
import { API_URL } from "./config";

function DemoSix({ parasize, menuvalue, font_type, bgValue, bgparaValue,colorFont,fairytales,lineHeight }) {
  const [success,setsuccess] = useState([])
  const [getdatas,setgetdatas] = useState([])
  const dispatch = useDispatch()

  const antiqueFontStyle = {
    fontSize:23
  };
  

  useEffect(()=>{
    axios.post(`${API_URL}/getAllPosts`).then((res)=>{
            // setsuccess(true)
            setgetdatas(res.data.blogInfo)

            setsuccess(true)
          }).catch(()=>{
            alert('something went wrong')
          })    
},[])


  if(success == false){
    return(
      <div>
      <div className="contentLoader">
      <ContentBlogLoader />
      </div>
      <div className="skeletonLoader">
      <BodyBlogLoader />
      </div>
      <div className="skeletonLoader">
      <BodyBlogLoader />
      </div>
      <div className="skeletonLoader">
      <BodyBlogLoader />
      </div>
    
    </div>
    )
  }
  return (

    <div className="App">
      
      {success == true ?  getdatas.filter(item => item.pageName === "about").map((item)=>{
        {}
        return (
          <>
          <div className="container-fluid post-container"> 
        <div className="row">
          <div className={`site-content ${font_type}`}>
          
            <div class={` post-sec frame1 ${bgValue}`}>
            <div className="fairly-main">
            {item.imageUrl ? (
                          <img className={`image1`} src={item.imageUrl} style={{ width: "98%" }}></img>
            ) : (
              ""
            )}
                <div
                    className="desc_wrapper_fairytale"
                  >
              <div style={{ 
                fontSize: `${parasize}px`,
                color: `#0F307A`,
                width: "100%",
                display: "inline-block",
                wordWrap: "break-word",
                lineHeight: "0.2em",
                paddingTop: 40,
                paddingBottom: 2,
                fontFamily: 'fairygod'
                }} class={`entry-contents `} dangerouslySetInnerHTML={{ __html: item.description }}>
              </div>
           </div>
           </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="separator"></div> */}
      </>
      )
      })  : ''}
          { getdatas.filter(item => item.pageName === "about").length === 0? 
     <div className="" style={{alignSelf:'center',marginTop:100,marginLeft:'auto',marginRight:'auto',display:'flex',flexDirection:'column'}}>

        <img src={images_nodata} alt="" width={100} height={100} style={{margin:'auto'}}/>
        <label style={{alignSelf:'center',marginTop:30,marginLeft:'auto',marginRight:'auto',display:'flex',flexDirection:'column'}}>No about information found!</label>
      </div> 
    :""
     }           
    </div>
  );
}


const mapStateToProps = state => ({
  fairytales: state.enroll.fairytales,
});
export default connect(mapStateToProps)(DemoSix);