import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ContentBlogLoader from "./Components/Loader/contentLoader";
import BodyBlogLoader from "./Components/Loader/bodyLoader";
import { fetchReflections } from "./Actions/reflections";
import images_nodata from "../src/images/new-menu-bg/images_nodata.png";
import Note from "./utils/Note";
import "./App.css";
import axios from "axios";
import { API_URL } from "./config";

function DemoFour({
  parasize,
  menuvalue,
  font_type,
  bgValue,
  bgparaValue,
  colorFont,
  reflections,
  lineHeight,
}) {
  // alert(parasize
  const [success, setsuccess] = useState([]);
  const [getdatas, setgetdatas] = useState([]);
  const currentYear = new Date().getFullYear(); //
  const [topImage_data, setTopImage_data] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReflections(setsuccess));
  }, []);

  useEffect(() => {
    if (reflections?.length !== 0) {
      setgetdatas(reflections);
      setsuccess(true);
    }
    axios
      .post(`${API_URL}/getAllPosts`)
      .then((res) => {
        // setsuccess(true)
        setTopImage_data(res.data.top_image);
      })
      .catch(() => {
        alert("something went wrong");
      });
  }, [reflections]);

  if (success == false) {
    return (
      <>
        <div className="contentLoader">
          <ContentBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
      </>
    );
  }
  return (
    <div className="App">
      {success == true
        ? getdatas.map((item,index) => {
          const decodedContent = item.description.replace(
            /&nbsp;/g,
            ' '
          );
            return (
              <>
                <div key={index} className="container-fluid post-container">
                  <div className="row">
                    <div className={`site-content ${font_type}`}>
                      <div class={`post-sec frame1 ${bgValue}`}>
                      {index === 0 && topImage_data !== null  ? (
                        <img className={`image1`} src={topImage_data} style={{marginBottom:40}}></img>
                      ) : (
                        <div></div>
                      )}


                        {(item?.pageName === "poems" && item.note !== null) ||
                        (item?.pageName === "reflections" &&
                          item.note !== null) ? (
                          <div
                            className="note-container"
                            style={{
                              paddingTop: 10,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {item.title !== null ? (
                              <h1
                                class={`entry-title`}
                                // style={{height:80}}
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              ></h1>
                            ) : null}
                            {(item?.pageName === "poems" &&
                              item.note !== null) ||
                            (item?.pageName === "reflections" &&
                              item.note !== null) ? (
                              <>
                                {item?.pageName === "poems" ||
                                item?.pageName === "reflections" ? (
                                  <div className="sticky-box">
                                    <Note note={item.note} bg_color={item.background_colour}/>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        ) : (
                          <>
                            {item.title.length !== 11 ? (
                              <h1
                                class={`entry-title`}
                                style={{paddingTop:20,}}
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              >
                              
                              </h1>
                            ) : null}
                          </>
                        )}
                        {item.imageUrl ? (
                          <img className={`image1`} src={item.imageUrl}></img>
                        ) : (
                          ""
                        )}
                        <div
                          className="desc_wrapper desc_wr"
                        >
                          {/* {item?.pageName === "reflections" && item.note !== null? <div className="poem-style">
                              <div style={{ fontSize: `${parasize}px`,color:`#000080`,width:'100%', display: 'inline-block', wordWrap: 'break-word',alignSelf:'center',fontFamily:`madeEvolve`}} class={`entry-content`} dangerouslySetInnerHTML={{ __html: item.description }}>
                            
                              </div>
                              {item?.pageName === "reflections" ?
                                <div><Note note={item.note}/></div>:null
                              }
                              </div>:
                              <> */}
                              <div
                              style={{
                                fontSize: `${parasize}px`,
                                color: `#0F307A`,
                                width: "100%",
                                display: "inline-block",
                                whiteSpace: "pre-wrap",
                                alignSelf: "center",
                                paddingTop:30,
                                paddingBottom: 20,
                              }}
                              className={`entry-content ${bgparaValue}`}
                              dangerouslySetInnerHTML={{
                                __html: item.description.replace(/&nbsp;/g, " "),
                              }}
                            ></div>
                          {/* </>} */}
                        </div>
                      </div>
                      {item?.pageName === "reflections" ? (
                        <div
                          className={`poem-reflection-footer`}
                          style={{
                            fontSize: 12,
                            fontFamily: `madeEvolve`,
                          }}
                        >
                          <h>
                            © {currentYear} Ritu Dhillon All Rights Reserved.{"     "}
                          </h>
                          <h>
                            {""}
                            {item.place ? `${item.place},` : ""}{" "}
                            {item.year ? item.year : ""}
                          </h>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
              </>
            );
          })
        : ""}
      {getdatas.length === 0 ? (
        <div
          className=""
          style={{
            alignSelf: "center",
            marginTop: 100,
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src={images_nodata}
            alt=""
            width={100}
            height={100}
            style={{ margin: "auto" }}
          />
          <label
            style={{
              alignSelf: "center",
              marginTop: 30,
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            No Reflections found!
          </label>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  reflections: state.enroll.reflections,
});

export default connect(mapStateToProps)(DemoFour);
