import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./App.css";
import Blog from "./Blog_page"
import Blog_creation from "./Blog_creation"
import Contact from "./Components/pages/Contact";
import AboutMe from "./Components/pages/AboutMe";
import Fairytale from "./Components/pages/Fairytale";
import 'antd/dist/reset.css';
import { Provider } from "react-redux";
import store from "./utils/configureStore";
import Poems from "./Components/pages/Poems";
import Reflections from "./Components/pages/Reflections";
import imgIcon from "./images/new-menu-bg";
import iconNow from "../src/images/offline_Icon.png"

function App(props) {
  const [isOnline,setIsOnline] = useState(navigator.onLine);

  useEffect(() => {

    function onlineHandler (){
      setIsOnline(true)
    } 

    function offlineHandler (){
      setIsOnline(false)
    }
    
    window.addEventListener("online",onlineHandler);
    window.addEventListener("offline",offlineHandler);

    return () => {
      window.addEventListener("online",onlineHandler);
      window.addEventListener("offline",offlineHandler);
    }
  }, [])
  
  return (
    <div>
    {isOnline ?<Provider store={store}>
        <BrowserRouter>
            <Routes>
            <Route path="/" exact element={<Blog />} />
                <Route path="/blogcreation"  element={<Blog_creation />} />
                <Route path="/contact"  element={<Contact />} />
                <Route path="/aboutme"  element={<AboutMe />} />
                <Route path="/fairytale"  element={<Fairytale />} />
                <Route path="/poem"  element={<Poems />} />
                <Route path="/reflections"  element={<Reflections />} />
                </Routes>
                </BrowserRouter>    
    </Provider> :
    <div style={{display:'flex',alignContent:'center',justifyContent:'center',flexDirection:'column'}}>
      <img src={iconNow} 
      style={{ 
        width: "150px",
        height: "150px",
        alignSelf:'center',
        marginTop:'250px',
        }} alt="" />
      <div className="onlineDivText">
       <p className="onlineText">You are offline. Please check your internet connection.</p>
       </div>
    </div>
    }
    </div>
  );
}

export default App;
