import axios from "axios";
import { API_URL } from "../config";
import { ADD_POEMS, ADD_TOP_IMAGE, FETCH_REFLECTIONS_LIST } from "./actionTypes";
import api from "../utils/api";


export const createReflections = (e,setsuccess,ids,setisupdate,isupdate,forimageapi,selectedColor,setIsLoading,quillContent,htmlContents,quillContents, htmlContentFOnts,
  quillContentFonts) => async dispatch => {
    setIsLoading(true)
    try {
      const encodedContent = e.descriptionHTML.replace(/ /g, '&nbsp;');
      let title_value = "";
      let formData = new FormData();
          formData.append('title',htmlContentFOnts);
          formData.append('image',forimageapi)
          formData.append('place',typeof e.place === 'undefined'? "":e.place === null?"":  e.place)
          formData.append('year',e.year === null ? "":isNaN(e.year)?"": e.year)
          // formData.append('year',"")
          formData.append('background_colour',selectedColor)
          formData.append('Quote',encodedContent) 
          formData.append('note',htmlContents.length === 11 ? "":htmlContents)
          formData.append('pageName',"reflections") 
        if(isupdate == true){
          formData.append('id',ids)
        }else{
          
        }
    const res = await axios.post(`${API_URL}/setBlogPost`, formData);
      dispatch({
        type: ADD_POEMS,
        payload: res.data.blogInfo
      });
      setsuccess(true)
      setIsLoading(false)
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  };


  export const fetchReflections = (setsuccess) => async dispatch => {
    const pageName = "reflections";
    try {
      const res = await api.post(`${API_URL}/getAllPosts`);
      let sortedReflections = res.data.blogInfo.filter(item => item.pageName === pageName).sort((a, b) => b.created_at.split('/').reverse().join().localeCompare(a.created_at.split('/').reverse().join()));
   
      dispatch({
        type: FETCH_REFLECTIONS_LIST,
        payload: sortedReflections
      });
      setsuccess(true)
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  }

  export const createTopImage = ( top_image,
    setImageLoading,
    setImageSuccess,setTopImage,setImageFile ) => async dispatch => {
    setImageLoading(true)
    try {
      let title_value = "";
      let formData = new FormData();
          formData.append('top_image',top_image) 
    const res = await axios.post(`${API_URL}/updateRefCoverImage`, formData);
      dispatch({
        type: ADD_TOP_IMAGE,
        payload: res.data.blogInfo
      });
      setImageLoading(false)
      setImageSuccess(true)
      setTopImage(null)
      
      setTimeout(() => {
        setImageSuccess(false); // Clear success message after 2 seconds
        setImageFile(null)
      }, 2000);
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  }